import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/FirebaseAuthContext";
import CheckoutForm from "../../components/plans/CheckoutForm";
import backgroundImage from "../../assets/images/plans-banner.png";
import MemberIdForm from "../../components/plans/MemberIdForm";
import { ChevronLeftIcon } from '@heroicons/react/20/solid'; // Import the ChevronLeftIcon

const plans = [
    {
        id: 'founding',
        price_id: 'price_1QHdXKKfha74CuuvuqP67eTQ'
    },
]

export default function CheckoutPage() {
    const { plan } = useParams();
    const navigate = useNavigate();
    const selectedPlan = plans.find(p => p.id === plan);
    const { userLoggedIn, firebaseUser } = useAuth();
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);
    const [memberId, setMemberId] = useState('');

    const handleMemberIdFormSuccess = (isAvailable, memberId) => {
        setShowCheckoutForm(isAvailable);
        setMemberId(memberId);
    };

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/login');
        }
    }, [userLoggedIn, firebaseUser]);

    return (
        <div className="h-screen bg-white">
            <div className="bg-gray-900 bg-no-repeat bg-center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'top' }}>
                <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
                    <div className="mx-auto max-w-4xl">
                        <p className="mt-8 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                            PCSL Checkout <br className="hidden sm:inline lg:hidden" />
                        </p>
                    </div>
                </div>
                <div className="pb-16">
                    <div className="-mt-24">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="mx-auto max-w-3xl rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10">
                                <h3 className="text-lg font-semibold leading-8 tracking-tight text-blue-800">Secure your Member ID</h3>
                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                    <p>With the founding membership plan, you can pick your own member number up to 6 digits</p>
                                </div>
                                <MemberIdForm onSuccess={handleMemberIdFormSuccess} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute top-4 left-4">
                <div className="relative bg-black bg-opacity-40 rounded-full px-4 py-2">
                    <button
                        onClick={() => navigate('/dashboard')}
                        className="flex items-center font-semibold text-md text-gray-100 hover:underline"
                    >
                        <ChevronLeftIcon className="h-5 w-5 mr-1" />
                        <span>Dashboard</span>
                    </button>
                </div>
            </div>

            {showCheckoutForm && <CheckoutForm memberId={memberId} priceId={selectedPlan.price_id} userEmail={firebaseUser.email} />}        
        </div>
    );
}
