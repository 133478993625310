import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { useAuth } from '../../contexts/FirebaseAuthContext';
import { GET_USER_BY_EMAIL_QUERY } from '../../utils/graphql/queries';
import Navbar from '../../components/navbar/Navbar';
import DashboardAvatarCard from '../../components/dashboard/DashboardAvatarCard';
import UpcomingMatches from '../../components/dashboard/UpcomingMatches';

export default function DashboardPage() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { userLoggedIn, firebaseUser } = useAuth();
    const [getUserByEmail, { loading, error, data: userData }] = useLazyQuery(GET_USER_BY_EMAIL_QUERY);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (userLoggedIn) {
            getUserByEmail({
                variables: {
                    email: firebaseUser.email,
                },
            });
        } else {
            navigate('/login');
        }

        if (!loading && userData) {
            setUser(userData.getUserByEmail);
        }

        // TODO: Handle errors
        if (error) {
            console.error('Error:', error);
        }
    }, [userLoggedIn, firebaseUser, user, getUserByEmail, loading, userData, error, navigate]);

    return (
        <div className="flex flex-col h-screen">
            <Navbar itemCurrent={'Dashboard'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> 
            <div className="flex-grow py-10 overflow-y-auto lg:pl-72">
                <div className="px-4 lg:px-12 space-y-4">
                    {user && <DashboardAvatarCard user={user} />}
                    <UpcomingMatches />
                </div>
            </div>
        </div>
    )
}
