import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '../../../contexts/FirebaseAuthContext';
import { GET_SURVEY_BY_NAME_QUERY, CREATE_SURVEY_RESPONSE_MUTATION, GET_USER_BY_EMAIL_QUERY, GET_SURVEY_RESULTS_BY_USER_ID_QUERY } from '../../../utils/graphql/queries';

const initialSteps = [
    { id: 0, name: 'Intro', path: '', imgLink: '/assets/images/survey-intro-cover.jpg' },
    { id: 1, name: 'Personal Fulfillment', path: 'personal-fulfillment', imgLink: '/assets/images/survey-personal-fulfillment-cover.jpg' },
    { id: 2, name: 'Love of the Game', path: 'love-of-the-game', imgLink: '/assets/images/survey-love-of-the-game-cover.jpg' },
    { id: 3, name: 'Community & Camaraderie', path: 'community-camaraderie', imgLink: '/assets/images/survey-community-camaraderie-cover.jpg' },
    { id: 4, name: 'Practical Application', path: 'practical-application', imgLink: '/assets/images/survey-practical-application-cover.jpg' },
    { id: 5, name: 'Achieving Greatness', path: 'achieving-greatness', imgLink: '/assets/images/survey-achieving-greatness-cover.jpg' },
    { id: 6, name: 'Results', path: 'results', imgLink: '/assets/images/survey-results-cover.jpg' },
];

export default function PracticalApplicationPage() {
    const navigate = useNavigate();
    const { userLoggedIn, firebaseUser } = useAuth();
    const [getUserByEmail, { userLoading, userError, data: userData }] = useLazyQuery(GET_USER_BY_EMAIL_QUERY);
    const [answers, setAnswers] = useState([]);
    const { loading, error, data } = useQuery(GET_SURVEY_BY_NAME_QUERY, { variables: { name: 'Competition Shooter Personality' } });
    const [createUsersSurveysAnswer] = useMutation(CREATE_SURVEY_RESPONSE_MUTATION);

    const { refetch: fetchSurveyResults } = useQuery(GET_SURVEY_RESULTS_BY_USER_ID_QUERY, {
        variables: { userId: userData ? parseInt(userData.getUserByEmail.id) : null, surveyName: 'Competition Shooter Personality' },
        skip: true, // Skip the query initially, it will be triggered manually by refetch
    });

    useEffect(() => {
        if (userLoggedIn) {
            getUserByEmail({
                variables: {
                    email: firebaseUser.email,
                },
            });
        } else {
            navigate('/login');
        }

        if (!loading && data) {
            setAnswers(data.getSurveyByName.surveysQuestions[3].question.answers);
        }


    }, [loading, data, userLoading, userData, userLoggedIn, firebaseUser, getUserByEmail, navigate]);

    const handleNavigation = async (stepPath) => {
        if (stepPath === 'results') {
            const { data: surveyData } = await fetchSurveyResults();

            if (surveyData) {
                const answers = surveyData.getSurveyResultsByUserId.map(result => parseFloat(result.answer.content));
                navigate(`/personality-survey/${stepPath}`, { state: { providedAnswers: answers } });
            }
        } else {
            navigate(`/personality-survey/${stepPath}`);
        }
    };

    const submitAnswer = async (selectedAnswerId) => {
        const user = userData.getUserByEmail;

        await createUsersSurveysAnswer({
            variables: {
                input: {
                    userId: user.id,
                    surveysQuestionId: data.getSurveyByName.surveysQuestions[3].id,
                    answerId: selectedAnswerId,
                },
            },
        });

        // Manually trigger the query to get the survey results and then navigate
        const { data: surveyData } = await fetchSurveyResults();

        if (surveyData) {
            const answers = surveyData.getSurveyResultsByUserId.map(result => parseFloat(result.answer.content));
            handleNavigation('achieving-greatness');
        }
    }

    return (
        <div className="flex flex-col h-full">
            <nav aria-label="Progress">
                <ol role="list" className="divide-y divide-gray-300 border border-gray-300 md:flex md:divide-y-0">
                    {initialSteps.map((step, index) => (
                        <li
                            key={step.name}
                            className="relative md:flex md:flex-1 cursor-pointer group"
                            onClick={() => handleNavigation(step.path)}
                        >
                            <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                                <span
                                    className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${index === 4 ? 'border-blue-800 text-blue-800' : 'border-gray-300 text-gray-500 group-hover:border-blue-800 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.id}
                                </span>
                                <span
                                    className={`ml-4 text-sm font-medium ${index === 4 ? 'text-blue-800' : 'text-gray-500 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.name}
                                </span>
                            </div>
                            {step.id !== initialSteps.length - 1 && (
                                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            <div className="w-full h-full">
                <div className="flex flex-col-reverse md:flex-row items-center md:items-start">
                    <div className="md:w-1/2 w-full relative">
                        <img
                            src={initialSteps[4].imgLink}
                            className="w-full h-auto"
                        />
                        <div className="absolute top-0 left-0 p-4">
                            <Link to="/dashboard" className="flex items-center font-semibold text-md text-white hover:underline">
                                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                                <span>Dashboard</span>
                            </Link>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full pt-4 px-6">
                        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Practical Application
                        </h1>
                        <p className="text-base sm:text-md md:text-lg lg:text-xl">
                        You find great satisfaction in knowing that the skills developed by competitive shooting translate into real-world competency in a variety of scenarios whether personal or professional. You view competitive shooting as an arena to test and hone your practical skills so that they become second nature and invaluable tools that will give you an advantage in other areas of your life when needed.
                        </p>
                        <p className="text-base sm:text-md md:text-lg lg:text-xl">
                            Rate between 1-10 depending on how much your interest in competitive shooting is influenced by Practical Application
                        </p>
                        {answers.length > 0 && (
                            <Formik
                                initialValues={{
                                    answer: answers[0].id
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    submitAnswer(values.answer);
                                    setSubmitting(false);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="my-2">
                                            <label htmlFor="answer" className="text-base sm:text-md md:text-lg lg:text-xl">
                                                Rating
                                            </label>
                                            <Field
                                                as="select"
                                                id="answer"
                                                name="answer"
                                                className="mb-2 border text-sm border-gray-300 rounded-md px-4 py-1 focus:outline-none focus:ring-blue-800 focus:border-blue-800;"
                                            >
                                                {answers.map(answer => (
                                                    <option key={answer.id} value={answer.id}>{answer.content}</option>
                                                ))}
                                            </Field>
                                        </div>
                                        <button type="submit" disabled={isSubmitting} className="text-center btn-primary my-4">
                                            Next
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}