import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const CountdownPage = () => {
  // Set the target date for 6:00 PM MST on November 16, 2024
  const targetDate = new Date('2024-11-16T18:00:00-0700').getTime(); // MST is UTC-7

  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(targetDate - new Date().getTime());
    }, 1000);

    // Clear interval when component is unmounted
    return () => clearInterval(timer);
  }, [targetDate]);

  // Function to format the remaining time into days, hours, minutes, and seconds
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24)); // calculate days
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // calculate hours
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)); // calculate minutes
    const seconds = Math.floor((time % (1000 * 60)) / 1000); // calculate seconds
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url("/assets/images/countdown.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: 'white',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        {/* "Memberships live in:" Label */}
        <div style={{ fontSize: '2rem', marginBottom: '20px' }}>
          {timeLeft > 0 ? 'Memberships live in:' : 'Memberships live now'}
        </div>

        {/* Countdown Numbers and Labels */}
        {timeLeft > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            {/* Days Section */}
            <div>
              <div style={{ fontSize: '3rem' }}>{days}</div>
              <div style={{ fontSize: '1.5rem' }}>Days</div>
            </div>

            {/* Hours Section */}
            <div>
              <div style={{ fontSize: '3rem' }}>
                {hours < 10 ? '0' + hours : hours}
              </div>
              <div style={{ fontSize: '1.5rem' }}>Hours</div>
            </div>

            {/* Minutes Section */}
            <div>
              <div style={{ fontSize: '3rem' }}>
                {minutes < 10 ? '0' + minutes : minutes}
              </div>
              <div style={{ fontSize: '1.5rem' }}>Minutes</div>
            </div>

            {/* Seconds Section */}
            <div>
              <div style={{ fontSize: '3rem' }}>
                {seconds < 10 ? '0' + seconds : seconds}
              </div>
              <div style={{ fontSize: '1.5rem' }}>Seconds</div>
            </div>
          </div>
        ) : (
          // Show "Sign Up" button once the countdown hits zero
          <div style={{ marginTop: '20px' }}>
            <Link
              to="/signup" // Navigate to the /signup route when the button is clicked
              className="px-4 py-2 bg-blue-800 text-center text-white text-lg font-semibold rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountdownPage;