import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import FormikCheckbox from '../common/FormikCheckbox';
import { UPDATE_USER_INTERESTS_MUTATION } from '../../utils/graphql/queries';

function FocusGroup({ userId, divisions, usersInterests }) {
    const [updateUserInterests] = useMutation(UPDATE_USER_INTERESTS_MUTATION);

    function isUserInterested(divisionId) {
        return usersInterests.some(userInterest => userInterest.matchTypeDivision.id === divisionId && userInterest.isSecondary);
    }

    function isPrimaryInterest(divisionId) {
        return usersInterests.some(userInterest => userInterest.matchTypeDivision.id === divisionId && userInterest.isPrimary);
    }

    const validationSchema = Yup.object().shape({
        divisions: Yup.array().of(
            Yup.object().shape({
                id: Yup.number().required(),
                selected: Yup.boolean(),
                primary: Yup.boolean(),
            })
        ).test('one-primary', 'Only one division can be the primary focus', divisions => {
            const primaryCount = divisions.filter(division => division.primary).length;
            return primaryCount <= 1;
        })
    });

    return (
        <Formik
        initialValues={{
            divisions: divisions.map(division => {
                const interested = isUserInterested(division.id);
                const primary = isPrimaryInterest(division.id);
                                
                return {
                    id: parseInt(division.id),
                    selected: interested,
                    primary: primary,
                };
            })
        }}        
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                const response = await updateUserInterests({
                    variables: {
                        input: {
                            interests: values.divisions,
                            userId: userId
                        }
                    }
                });
                setSubmitting(false);
            }}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="overflow-x-auto">
                        <table className="min-w-full md:min-w-2xl divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                        Division
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                        Interested
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                        Primary Focus
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {divisions.map((division, index) => (
                                    <tr key={division.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {division.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <FormikCheckbox name={`divisions[${index}].selected`} className="checkbox" />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <FormikCheckbox name={`divisions[${index}].primary`} className="checkbox" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {errors.divisions && touched.divisions && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.divisions}
                            </div>
                        )}
                    </div>
                    <div className="mt-4">
                        <button type="submit" className="btn-primary">
                            Save
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default FocusGroup;
