import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useAuth } from '../../contexts/FirebaseAuthContext';
import { doSignOut } from '../../utils/firebase/auth';
import { GET_SURVEY_RESULTS_BY_USER_ID_QUERY, GET_USER_BY_EMAIL_QUERY } from '../../utils/graphql/queries';
import Navbar from '../../components/navbar/Navbar';
import AccountInformation from '../../components/profile/AccountInformation';
import CompetitorPersonalityChart from '../../components/profile/CompetitorPersonalityChart';
import Focuses from '../../components/profile/Focuses';
import ViewProfile from '../../components/profile/ViewProfile';
import CommunityInformation from '../../components/profile/CommunityInformation';

export default function AccountPage() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { userLoggedIn, firebaseUser } = useAuth();
    // const [getUserByEmail, { loading, error, data: userData }] = useLazyQuery(GET_USER_BY_EMAIL_QUERY);
    // const [getSurveyAnswers, { loading: surveyAnswersLoading, error: surveyAnswersError, data: surveyAnswersData }] = useLazyQuery(GET_SURVEY_RESULTS_BY_USER_ID_QUERY);
    const [surveyAnswers, setSurveyAnswers] = useState([]);
    const [user, setUser] = useState(null);

    const [getSurveyResultsByUserId] = useLazyQuery(GET_SURVEY_RESULTS_BY_USER_ID_QUERY, {
        onCompleted: (data) => {
            const answers = data.getSurveyResultsByUserId.map(result => parseFloat(result.answer.content));
            setSurveyAnswers(answers);
        }
    });


    const { data: userData } = useQuery(GET_USER_BY_EMAIL_QUERY, {
        variables: { email: firebaseUser?.email },
        skip: !(userLoggedIn && firebaseUser),
        onCompleted: (data) => {
            setUser(data.getUserByEmail);
        },
    });

    const handleLogoutClick = () => {
        doSignOut();
    }

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/login');
        }

        if (user) {
            getSurveyResultsByUserId({
                variables: { userId: parseInt(user.id), surveyName: 'Competition Shooter Personality' },
            });
        }

        // if (error) {
        //     console.error('Error:', error);
        // }

        // if (surveyAnswersError) {
        //     console.error('Survey Answers Error:', surveyAnswersError);
        // }
    }, [userLoggedIn, firebaseUser, user]);

    return (
        <div>
            <Navbar itemCurrent={''} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="py-10 lg:pl-72">
                <div className="px-4 sm:px-6 lg:px-8 space-y-4">
                    {user && (
                        <div>
                            <div className="flex flex-col lg:flex-row mb-3">
                                <div className="lg:basis-1/4 mb-3 lg:mb-0 lg:mr-3">
                                    <ViewProfile user={user} />
                                </div>
                                <div className="lg:basis-3/4">
                                    <CommunityInformation user={user} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <AccountInformation user={user} />
                            </div>
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-3">
                                <div className="flex flex-col sm:flex-row">
                                    <div className="w-full sm:w-1/3 px-4 py-6 sm:px-6">
                                        <h3 className="text-base font-semibold leading-7 text-gray-900">Competition Shooting Personality</h3>
                                        <p>Shooters find satisfaction through participating in competitive shooting for a variety of reasons. Here are five distinct categories that shooters may relate to in varying degrees.</p>
                                        <p>
                                            You can {' '}
                                            <Link
                                                to="/personality-survey"
                                                className="link"
                                            >
                                                update
                                            </Link>
                                            {' '} this at any time.
                                        </p>
                                    </div>
                                    <div className="max-w-xl sm:w-2/3 pt-6 px-7">
                                        {user && <CompetitorPersonalityChart userAnswers={surveyAnswers} />}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                {user && <Focuses userId={user.id} usersInterests={user.usersInterests} />}
                            </div>
                            <button onClick={handleLogoutClick} className="text-center btn-primary">
                                Log out
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}