import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

const TermsPage = () => {
    return (
        <div style={styles.container}>
            <div className="mb-2">
                <Link to="/login" className="flex items-center font-semibold text-md text-black hover:underline">
                    <ChevronLeftIcon className="h-5 w-5 mr-1" />
                    <span>Back to Login</span>
                </Link>
            </div>
            <h1 style={styles.title}>TERMS AND CONDITIONS OF USE</h1>
            <p><strong>Last Updated:</strong> 05/16/2024</p>
            <br></br>
            <p>Welcome to the official website (our “Site”) of Practical Competition Shooting League (“PCSL”).</p>
            <br></br>

            <p>These terms and conditions (“<strong>Terms</strong>”) apply to your use of this Site. Please note that these Terms apply regardless of the means of delivery of the Site to you. Any reference to “you” or “your” is a reference to you as a user of the PCSL Site.</p>
            <br></br>

            <p><b>THESE TERMS AND CONDITIONS CONTAIN WARRANTY DISCLAIMERS, OTHER PROVISIONS THAT LIMIT PCSL’S LIABILITY, AND A BINDING ARBITRATION CLAUSE AND CLASS ACTIONS WAIVER THAT IMPACT YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ THESE TERMS CAREFULLY. USING, ACCESSING AND/OR BROWSING THIS SITE CONSTIUTES ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY EACH AND EVERY TERM AND CONDITION SET FORTH HEREIN, PLEASE EXIT THIS SITE IMMEDIATELY AND DO NOT USE, ACCESS AND/OR BROWSE IT FURTHER.</b></p>
            <br></br>

            <h2 style={styles.underlinedTitle}><strong>1. YOUR OBLIGATIONS REGARDING USE OF THE SITE</strong></h2>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    <strong>1.1. Limitations on Use.</strong> You agree to use our Site in a manner consistent with these Terms. Further, you may not do any of the following while using our Site:
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>1.1.1.</strong> Interfere with or disrupt networks connected to our site or violate the regulations, policies, or procedures of such networks;</li>
                        <li style={styles.li}><strong>1.1.2.</strong> Attempt to gain unauthorized access to our Site, computer systems or networks connected to our Site, through password mining or any other means;</li>
                        <li style={styles.li}><strong>1.1.3.</strong> Interfere with another user’s use and enjoyment of our Site;</li>
                        <li style={styles.li}><strong>1.1.4.</strong> Upload any message, information, data, text, software, graphic files or other content (“<strong>Content</strong>”) that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, that may be invasive of another’s privacy, hateful, racially, ethnically or otherwise objectionable;</li>
                        <li style={styles.li}><strong>1.1.5.</strong> Upload Content that contains a virus, corrupted file, or other harmful component;</li>
                        <li style={styles.li}><strong>1.1.6.</strong> Impersonate any person or entity, including, but not limited to, PCSL personnel, or falsely state or otherwise misrepresent any affiliation with any person or entity;</li>
                        <li style={styles.li}><strong>1.1.7.</strong> Upload any Content that you do not have a right to upload under law or under contractual or fiduciary relationships (including, but not limited to, nondisclosure agreements);</li>
                        <li style={styles.li}><strong>1.1.8.</strong> Upload any information or grant permissions to view information to any person in a manner that exceeds your contractual or other legal authority;</li>
                        <li style={styles.li}><strong>1.1.9.</strong> Upload any unsolicited advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, contests, surveys, or any other form of unauthorized solicitation (commercial or non-commercial);</li>
                        <li style={styles.li}><strong>1.1.10.</strong> Intentionally or unintentionally violate or encourage or assist another to violate any law or regulation in connection with your use or another’s use of our Site; or</li>
                        <li style={styles.li}><strong>1.1.11.</strong> Upload any Content that violates or infringes any patent, trademark, trade secret, copyright or any right, of whatever nature, of anyone.</li>
                        <li style={styles.li}><strong>1.1.12.</strong> If you violate any part of this Section 1.1, your permission to use our Site automatically terminates.</li>
                    </ol>
                </li>
                <li style={styles.li}>
                    <strong>1.2. PCSL Match Levels.</strong>
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>1.2.1.</strong> PCSL offers shooting matches categorized into four levels, each with distinct characteristics and levels of involvement from PCSL:
                            <ol style={styles.ol}>
                                <li style={styles.li}><strong>1.2.1.1.</strong> <span style={styles.underlinedTitle}> Level 1 - Local Weekend Matches</span>: These matches are local weekend events where PCSL is not involved in any capacity. You should note that PCSL assumes no responsibility for the organization or management of these matches.</li>
                                <li style={styles.li}><strong>1.2.1.2.</strong> <span style={styles.underlinedTitle}> Level 2 - Smaller Championships</span>: Level 2 matches consist of smaller championships where PCSL's direct involvement is very minimal, if any. You should be aware that PCSL's role in these events may vary, and PCSL does not have extensive involvement in the organization or management of these matches.</li>
                                <li style={styles.li}><strong>1.2.1.3.</strong> <span style={styles.underlinedTitle}> Level 3 - Regional Championships</span>: Level 3 matches are regional championships held once per region per year. PCSL takes an active role in organizing and running these championships. You should expect PCSL's involvement in the coordination and management of these events.</li>
                                <li style={styles.li}><strong>1.2.1.4.</strong> <span style={styles.underlinedTitle}> Level 4 - National Championships</span>: Level 4 matches represent the national championships of PCSL, held once annually. These matches are entirely produced and managed by PCSL. You can expect PCSL to have full control over the organization, management, and production of these prestigious events.</li>
                            </ol>
                        </li>
                        <li style={styles.li}><strong>1.2.2.</strong> By participating in, observing, working at, or vending at any match, regardless of level, you agree to abide by and observe all gun safety laws mandated by state and federal regulations/laws and industry standards.</li>
                    </ol>
                </li>
                <li style={styles.li}><strong>1.3. Requirement to Abide by Rules.</strong> You agree to abide by all PCSL policies stated herein and in the <strong><span styles={styles.underlinedTitle}>Privacy Policy</span></strong>, as updated from time to time, state, local, and federal laws for the duration of your participation in any PCSL event, while utilizing or accessing the PCSL Site and/or for the duration of your Membership (as defined below) with PCSL, if applicable. You understand that failure to abide these expectations may result in your immediate removal from participating in the event, and appropriate PCSL or legal consequences. You further agree to abide by all policies and procedures stated in the <strong><span styles={styles.underlinedTitle}>PCSL General Rulebook</span></strong>, as updated from time to time, and any and all rules provided at any PCSL event or otherwise listed on this Site.</li>
                <li style={styles.li}><strong>1.4. Warranties.</strong> You warrant that you are of legal age to purchase or carry a firearm pursuant to applicable State or Federal laws. You further warrant that you will at all times during any PCSL event, maintain safe practices as further detailed in the PCSL General Rulebook and supervise and maintain responsibility for any and all minors, if permitted, that may accompany you to any such event.</li>
                <li style={styles.li}><strong>1.5. Right to Monitor.</strong> PCSL reserves the right to monitor all materials provided on our Site and to remove any which, in the absolute discretion of PCSL, is deemed to be offensive or otherwise in breach of these Terms. <strong>ALL MATERIAL DISPLAYED BY PCSL ON ITS SITE IS FOR INFORMATIONAL PURPOSES ONLY.</strong></li>
                <li style={styles.li}><strong>1.6. Right to Terminate Account.</strong> PCSL may, at its discretion and in appropriate circumstances, terminate the accounts of users who infringe the rights of copyright holders, or other the rights of other PCSL Members.</li>
                <li style={styles.li}><strong>1.7. Confidential Use of Membership Information.</strong> If you subscribe to a PCSL Membership (“<strong>Membership</strong>”), you may have access to the Members (“<strong>Members</strong>”) only area of the Site and may have access to confidential Membership information, including email addresses, phone numbers, and other contact information. This information may only be used in the course of PCSL business or for the completion of duties assigned to PCSL volunteers. Improper use or dissemination of this information could result in denial of access to the Members area, restriction from participating in PCSL events, termination of PCSL Membership, and/or further legal action by PCSL.</li>
                <li style={styles.li}><strong>1.8. Refund Policy.</strong> PCSL is dedicated to ensuring customer satisfaction. <strong><span style={styles.underlinedTitle}>If you are not satisfied with your Membership, please contact us by email at info@pcsleague.com within 30 calendar days of subscribing to Membership.</span></strong>
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>1.8.1.</strong> To qualify for a refund, Members must not have used their Membership to attend a level 3 or level 4 match (see Section 1.2 above for description of match levels).  Additionally, we require that all Members must have a 5-minute phone call with us prior to cancelling their Membership. This allows us to determine eligibility for a refund and to understand how we may have fallen short of your expectations as a Member.</li>
                        <li style={styles.li}><strong>1.8.2.</strong> Once eligibility for a refund and Membership cancellation is determined, at our sole discretion, we will terminate your subscription, and no further payments will be necessary. Refunds will be issued to the original payment method used for the Membership purchase. Upon refund issuance, access to the Member area will be immediately revoked.</li>
                        <li style={styles.li}><span style={styles.underlinedTitle}><strong>1.8.3. Once eligibility for a refund and Membership cancellation is determined, at our sole discretion, we will terminate your subscription, and no further payments will be necessary. Refunds will be issued to the original payment method used for the Membership purchase. Upon refund issuance, access to the Member area will be immediately revoked.</strong></span></li>
                    </ol>
                </li>
                <li style={styles.li}><strong>1.9. Updates.</strong> After purchasing a Membership from us, you will be registered to receive periodic updates and announcements from PCSL. If you are not interested in receiving any further emails from PCSL, you can email us back requesting no future emails and your information will be promptly removed from PCSL’s email distribution list. It is important to note that un-subscribing from email delivery does not cancel your access to the Membership area.</li>
                <li style={styles.li}>
                    <strong>1.10. Minors:</strong>
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>1.10.1.</strong> Minors, defined as individuals under the age of 18 years old, may participate in PCSL matches under the following conditions:</li>
                        <li style={styles.li}><strong>1.10.2.</strong> All minors must be accompanied by a parent or legal guardian or other responsible adult (“<strong>Accompanying Adult</strong>”), who assumes full responsibility for the minor's safety and conduct while on the range premises.</li>
                        <li style={styles.li}><strong>1.10.3.</strong> Prior to participation, minors and their Accompanying Adults must undergo a safety briefing conducted by a certified Range Safety Officer (“<strong>RSO</strong>”) or match official, covering the rules and procedures outlined within the provided PCSL General Rulebook, with additional emphasis on safety protocols tailored to minors.</li>
                        <li style={styles.li}><strong>1.10.4.</strong> Firearms handling by minors must be directly supervised by the Accompanying Adult or under the direct supervision of a certified RSO or match official <strong>at all times.</strong></li>
                        <li style={styles.li}><strong>1.10.5.</strong> Minors are prohibited from handling firearms unsupervised or participating in any firearms-related activities without direct supervision of the Accompanying Adult.</li>
                        <li style={styles.li}><strong>1.10.6.</strong> All minors and their Accompanying Adults must adhere to the range safety rules outlined in the provided PCSL General Rulebook, and must abide by the rules outlined in the website Terms and Conditions and the Privacy Policy, as amended from time to time, found on the PCSL website.</li>
                        <li style={styles.li}><strong>1.10.7.</strong> The Adult Accompanying the minor is accountable for ensuring the minor's compliance with all safety rules and procedures.</li>
                        <li style={styles.li}><strong>1.10.8.</strong> Any violation of safety rules by a minor may result in immediate disqualification from participation and expulsion from the range premises, with further consequences subject to the discretion of match officials.</li>
                        <li style={styles.li}><strong>1.10.9.</strong> It is the responsibility of the Accompanying Adult to ensure that firearms are stored securely and inaccessible to minors when not in use.</li>
                        <li style={styles.li}><strong>1.10.10.</strong> The Match director reserves the right to enforce additional safety measures or restrictions specific to minors' participation as deemed necessary for their safety and the safety of others.</li>
                    </ol>
                </li>
            </ol>
            <h2 style={styles.underlinedTitle}><strong>2. INTELLECTUAL PROPERTY</strong></h2>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    <strong>2.1. Copyright.</strong> All content included on this Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of PCSL or its content suppliers and is protected by U.S. and international copyright laws. The compilation of all content on this Site is the exclusive property of PCSL, with copyright authorship for this collection by PCSL, and protected by U.S. and international copyright laws.
                    <br></br>
                    In the event you believe that material or content published on the Site may infringe on your copyright or that of another, please <span style={styles.underlinedTitle}>contact</span> PCSL at info@pcsleague.com.
                    <strong>2.2. Trademarks.</strong> PCSL trademarks and service marks may not be used in connection with any product or service that is not owned by or affiliated with PCSL, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits PCSL. All other trademarks not owned by PCSL or its affiliates that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by PCSL.
                </li>
                <li style={styles.li}>
                    <strong>2.2. Trademarks.</strong> PCSL trademarks and service marks may not be used in connection with any product or service that is not owned by or affiliated with PCSL, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits PCSL. All other trademarks not owned by PCSL or its affiliates that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by PCSL.
                </li>
                <li style={styles.li}>
                    <strong>2.3. Fair Use Disclaimer.</strong> The law has made it acceptable, under very specific circumstances and for very specific purposes only, for one to use someone else’s copyrighted work without first requiring their consent. The following purposes are commonly considered to be “fair use” under Title 17, Section 107 of the United States Code under applicable circumstances and uses, and thus, may not be considered copyright infringement:
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>2.3.1.</strong> Criticism</li>
                        <li style={styles.li}><strong>2.3.2.</strong> Comment</li>
                        <li style={styles.li}><strong>2.3.3.</strong> News reporting</li>
                        <li style={styles.li}><strong>2.3.4.</strong> Teaching Scholarship</li>
                        <li style={styles.li}><strong>2.3.5.</strong> Research</li>
                    </ol>
                    PCSL may use copyrighted material in the manner set forth above. PCSL believes these specific uses constitute "fair use."
                </li>
                <li style={styles.li}>
                    <strong>2.4. Publicity Release.</strong> PCSL may display on this Site, or other official PCSL publication, any and all photographs, audio-visual, or audio recordings of you and/or your likeness or voice captured by PCSL, a PCSL affiliate, volunteer, or local media in connection with your participation in, or in attendance, at a PCSL or PCSL affiliate event in accordance with this Section 2.4 PCSL Publicity Release Policy. All photographs, audio-visual, or audio recordings of you and/or your likeness or voice may be used by PCSL for commercial and non-commercial uses, without restriction. You hereby waive the right to inspect or approve the finished product wherein your image or likeness appears. Additionally, you waive any right to payment, royalties or other compensation arising from or related to the use of your image or likeness. <strong>You hereby agree to hold harmless and release and forever discharge PCSL, its parent companies, affiliates, assigns, employees, and contractors from any and all claims, demands, and causes of action which you, your heirs, representatives, or any other persons acting on your behalf have in connection with this use.</strong>
                </li>
                <li style={styles.li}>
                    <strong>2.5. Publicity Release for Minors.</strong> PCSL may display on this Site, or other official PCSL publication, any and all photographs, audio-visual, or audio recordings of Minor’s likeness or voice captured by PCSL, a PCSL affiliate, volunteer, or local media in connection with Minor’s participation in, or in attendance, at a PCSL or PCSL affiliate event in accordance with this Section 2.4 PCSL Publicity Release Policy. You, as the Parent/Guardian/Accompanying Adult, on behalf of Minor, hereby consent that all photographs, audio-visual, or audio recordings of Minor and/or Minor’s likeness or voice may be used by PCSL for commercial and non-commercial uses, without restriction. You, as the Parent/Guardian/Accompanying Adult, on behalf of minor, hereby waive the right to inspect or approve the finished product wherein Minor’s image or likeness appears. Additionally, you waive any right to payment, royalties or other compensation arising from or related to the use of minor’s image or likeness. <strong>You hereby agree to hold harmless and release and forever discharge PCSL, its parent companies, affiliates, assigns, employees, and contractors from any and all claims, demands, and causes of action which Minor, you, your heirs, representatives, or any other persons acting on your behalf have in connection with this use.</strong>
                </li>
            </ol>
            <h2 style={styles.underlinedTitle}><strong>3. DISCLAIMER OF WARRANTIES, CLAIMS, INDEMNIFICATION AND LIMITATION OF LIABILITY</strong></h2>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    <strong>3.1. WARRANTIES.</strong> THIS SITE IS PROVIDED BY PCSL ON AN "AS IS" AND "AS AVAILABLE" BASIS. PCSL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, PCSL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. PCSL DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM PCSL ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                </li>
                <li style={styles.li}>
                    <strong>3.2. LIMITATION OF LIABILITY.</strong> PCSL WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR PCSL MEMBERSHIP, THE USE OF THIS SITE, OR PARTICIPATION IN ANY PCSL EVENT, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND SECTION 3.3 SHALL BE APPLICABLE. 
                </li>
                <li style={styles.li}>
                    <strong>3.3. CAP ON LIABILITY:</strong> IN NO EVENT WILL THE COLLECTIVE LIABILITY OF PCSL, OR ANY OF ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS AND REPRESENTATIVES, TO YOU FOR ANY AND ALL DAMAGES, INJURIES, AND LOSSES ARISING FROM ANY AND ALL CLAIMS AND CAUSES OF ACTION ARISING OUT OF, BASED ON, RESULTING FROM, OR IN ANY WAY RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY YOU TO PCSL UNDER THIS AGREEMENT DURING THE 12-MONTH PERIOD PRECEDING THE DATE OF THE CLAIM. IN THE CASE OF EXCLUDED CLAIMS, SUCH LIMIT WILL BE EQUAL TO ONE TIMES THE TOTAL AMOUNT PAID BY YOU TO PCSL UNDER THIS AGREEMENT DURING THE TERM. THE EXISTENCE OF MULTIPLE CLAIMS OR SUITS UNDER OR RELATED TO THIS AGREEMENT WILL NOT ENLARGE OR EXTEND THE LIMITATION OF MONEY DAMAGES WHICH WILL BE THE CLAIMANT’S SOLE AND EXCLUSIVE REMEDY.
                </li>
                <li style={styles.li}>
                    <strong>3.4. Indemnification.</strong> You agree to defend, indemnify and hold harmless PCSL and its affiliates, sponsors and their officers, directors, employees and agents, from and against any and all claims, including but not limited to third-party claims, liability, actions, demands, costs or expenses (including, but not limited to, attorney’s fees) arising from or relating to your participation in any PCSL event, your use of the Site or any materials provided on the Site, your Membership to PCSL, or your breach or violation of these Terms. PCSL reserves the right to defend any such claim, and you agree to provide PCSL with such reasonable cooperation as it may request.
                </li>
                <li style={styles.li}>
                    <strong>3.5. Release of Claims.</strong> You wish to utilize the PSCL Site and/or participate in a PCSL event. You understand that you do so at your own risk. In exchange for being permitted to participate in PCSL events and utilize PCSL’s Site, you hereby release the PCSL and all of its employees, officers, agents, representatives, contractors, attorneys and volunteers from any and all liability for and hereby waive any and all claims for any loss, damage, injuries to person or property, death, claims, demands, lawsuits, expenses and any other liability of any kind, or of to you or any other person, directly or indirectly arising out of or in connection with you participation in or attendance at the event or use of the Site.
                </li>
            </ol>
            <h2 style={styles.underlinedTitle}><strong>4. LINKS TO THIRD PARTY WEBSITES OR SERVICES</strong></h2>
            <p style={styles.ol}>This Site may contain links to other websites (“Linked Sites”). The Linked Sites are not under the control of PCSL and PCSL is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. PCSL is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by PCSL of the Linked Site or any association with its operators. When you select a link to an outside website, you are leaving PCSL’s Site and are subject to the terms of the owners/sponsors of that outside website.</p>
            <h2 style={styles.underlinedTitle}><strong>5. PRIVACY</strong></h2>
            <p style={styles.ol}>PCSL takes the security of your personal data seriously and your use of this Site is subject to PCSL’s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
            <h2 style={styles.underlinedTitle}><strong>6. LICENSE AND WEBSITE ACCESS</strong></h2>
            <p style={styles.ol}>PCSL grants you a limited license to access and make personal use of this Site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of PCSL. This license does not include any resale or commercial use of this Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This Site or any portion of this Site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of PCSL. You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of PCSL and/or our associates without PCSL’s express written consent. You may not use any meta tags or any other "hidden text" utilizing PCSL’s name or trademarks without PCSL’s express written consent. Any unauthorized use terminates the permission or license granted by PCSL.</p>
            <p style={styles.ol}>You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of PCSL so long as the link does not portray PCSL, its associates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any PCSL logo or other proprietary graphic or trademark as part of the link without express written permission from PCSL.</p>
            <h2 style={styles.underlinedTitle}><strong>7. MISCELLANEOUS</strong></h2>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    <strong>7.1. Governing Law and Jurisdiction.</strong> These Terms will be governed by and construed in accordance with the laws of the State of Arizona, and you submit to the non-exclusive jurisdiction of the state and federal courts located in the State of Arizona, Maricopa County for the resolution of any disputes.
                </li>
                <li style={styles.li}>
                    <strong>7.2. Dispute Resolution.</strong> 
                    <ol style={styles.ol}>
                        <li style={styles.li}><strong>7.2.1.</strong> PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS.</li>
                        <li style={styles.li}><strong>7.2.2.</strong> To expedite and control the cost of disputes, <strong>any dispute or claim relating to your use of any products or services sold or distributed by PCSL or through this Site will be resolved by binding arbitration, rather than in court,</strong> except that you may assert claims in small claims court, if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to these Terms.</li>
                        <li style={styles.li}><strong>7.2.3.</strong> <strong> There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages) and must follow these Terms as a court would.</strong></li>
                        <li style={styles.li}><strong>7.2.4.</strong> To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent PCSL, LLC, Attn: Maxwell Gregory Leograndis, 24247 N. 169th Drive, Surprise, AZ 85387. The arbitration will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA’s Supplementary Procedures for Consumer-Related Disputes. The AAA’s rules are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules, and split equally by both parties. The successful party (as determined by the Arbitrator), shall be permitted to seek reimbursement of their attorney’s fees and costs. You may request to have the arbitration conducted by Zoom, based on written submissions, or in person in Arizona State at a mutually agreed upon location.</li>
                        <li style={styles.li}><strong>7.2.5.</strong> <strong>You agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action.</strong> Any determination made by an Arbitrator shall be binding, and may not be appealed in a court of law. </li>
                    </ol>
                </li>
                <li style={styles.li}>
                    <strong>7.3. Modifications:</strong> PCSL reserves the right to update these Terms at any time without notice to you. The most current version of the Terms can be reviewed by clicking on the “Terms of Use” hypertext link located at the bottom of the PCSL Site.
                </li>
                <li style={styles.li}>
                    <strong>7.4. Contact.</strong> If you have any questions about the terms and conditions or disclaimers, you may contact us on our Site, or via info@pcsleague.com. 
                </li>
                <li style={styles.li}>
                    <strong>7.5. Revision of Terms.</strong> PCSL is permitted to revise these Terms at any time as it sees fit, and by using this Site you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of the Site.
                </li>
                <li style={styles.li}>
                    <strong>7.6. Entire Agreement.</strong> These Terms, including any legal notices and disclaimers contained on this Site, constitute the entire agreement between PCSL and you in relation to your use of this Site, and supersedes all prior agreements and understandings with respect to the same.
                </li>
            </ol>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: '20px',
        padding: '20px',
    },
    title: {
        color: '#333',
    },
    underlinedTitle: {
        color: '#333',
        textDecoration: 'underline',
    },
    // Style for the ordered list and nested lists
    ol: {
        marginLeft: '20px',  // Indentation for nested lists
        paddingLeft: '0',    // Remove default padding
    },
    li: {
        marginBottom: '5px',  // Adds some space between list items if desired
        paddingLeft: '0',    // Ensure no extra padding
        lineHeight: '1.5',   // Control line height for consistency
    },
};


export default TermsPage;