import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import {
    Bars3Icon,
    HomeIcon,
    ShoppingCartIcon,
    UserIcon,
    TrophyIcon,
    UserGroupIcon,
    ChatBubbleLeftRightIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const navItems = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, comingSoon: false },
    { name: 'Forums', href: 'https://forums.pcsleague.com', icon: ChatBubbleLeftRightIcon, comingSoon: false },
    { name: 'Leaderboards', href: '#', icon: TrophyIcon, comingSoon: true },
    { name: 'Clubs', href: '#', icon: UserGroupIcon, comingSoon: true },
    { name: 'Membership', href: '/membership', icon: ShoppingCartIcon, comingSoon: false },
];

export default function Navbar({ itemCurrent, sidebarOpen, setSidebarOpen }) {
    const navigate = useNavigate();
    const navMap = navItems.map(item => ({
        ...item,
        current: item.name === itemCurrent,
    }));

    useEffect(() => {
        navMap.forEach(item => {
            item.current = item.name === itemCurrent;
        });
    }, [itemCurrent, navMap]);

    const handleNavigation = (href, isExternal) => {
        if (isExternal) {
            window.open(href, '_blank', 'noopener,noreferrer');
        } else {
            navigate(href);
        }
        setSidebarOpen(false); // Close sidebar on navigation
    };

    const handleAccountClick = () => {
        navigate('/account');
    };

    const renderNavItems = () =>
        navMap.map(item => (
            <li key={item.name}>
                {item.href.startsWith('http') ? (
                    <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(
                            item.current ? 'bg-gray-50 text-blue-800' : 'text-gray-700 hover:text-blue-800 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                    >
                        <item.icon
                            className={classNames(
                                item.current ? 'text-blue-800' : 'text-gray-400 group-hover:text-blue-800',
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                        />
                        {item.name}
                        {item.comingSoon && (
                            <span className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                                Coming Soon
                            </span>
                        )}
                    </a>
                ) : (
                    <button
                        type="button"
                        onClick={() => handleNavigation(item.href, false)}
                        className={classNames(
                            item.current ? 'bg-gray-50 text-blue-800' : 'text-gray-700 hover:text-blue-800 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                    >
                        <item.icon
                            className={classNames(
                                item.current ? 'text-blue-800' : 'text-gray-400 group-hover:text-blue-800',
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                        />
                        {item.name}
                        {item.comingSoon && (
                            <span className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                                Coming Soon
                            </span>
                        )}
                    </button>
                )}
            </li>
        ));

    return (
        <>
            {/* Mobile sidebar */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src="/assets/images/pcsl-logo-letters-only.png"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {renderNavItems()}
                                                </ul>
                                            </li>
                                            <li className="-mx-6 mt-auto">
                                                <button
                                                    type="button"
                                                    onClick={handleAccountClick}
                                                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-700 hover:text-blue-800 hover:bg-gray-50"
                                                >
                                                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                                                    <span className="sr-only">Your account</span>
                                                    <span>Account</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Mobile navbar toggle button */}
            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* Rest of your navbar code */}
            </div>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-8 w-auto"
                            src="/assets/images/pcsl-logo-letters-only.png"
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {renderNavItems()}
                                </ul>
                            </li>
                            <li className="-mx-6 mt-auto">
                                <button
                                    type="button"
                                    onClick={handleAccountClick}
                                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-700 hover:text-blue-800 hover:bg-gray-50"
                                >
                                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                                    <span className="sr-only">Your account</span>
                                    <span>Account</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}
