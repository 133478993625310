import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

const PrivacyPage = () => {
    return (
        <div style={styles.container}>
            <div className="mb-2">
            <Link to="/login" className="flex items-center font-semibold text-md text-black hover:underline">
                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                <span>Back to Login</span>
            </Link>
            </div>
            <h1 style={styles.title}>Privacy Policy</h1>
            <p><strong>Effective as of:</strong> 05/16/2024</p>
            
            <p>Your privacy is important to us. It is Practical Competition Shooting League's (“us” or “PCSL”) policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://www.pcsleague.com">https://www.pcsleague.com</a>, and other sites we own and operate (our “site” or “website”).</p>
            
            <p>Our privacy policy (“Privacy Policy”) is designed to assist you in understanding how we collect and use the personal information you provide us with and to assist you in making informed decisions when using our site.</p>
            
            <h2 style={styles.subtitle}>Information We Collect</h2>
            <ul>
                <li><strong>Log data:</strong> When you visit our website, our servers may automatically log the standard data provided by your web browser. This may include your computer’s Internet Protocol (IP) address, browser type and version, pages you visit, time and date of your visit, time spent on each page, and other details.</li>
                <li><strong>Device data:</strong> We may collect data about the device you are using to access our website. This data may include device type, operating system, unique device identifiers, device settings, and geo-location data.</li>
                <li><strong>Personal information:</strong> We may ask for personal information, such as your:
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Social media profiles</li>
                        <li>Date of birth</li>
                        <li>Phone/mobile number</li>
                        <li>Home/Mailing address</li>
                        <li>Payment information</li>
                    </ul>
                </li>
            </ul>

            <h2 style={styles.subtitle}>Your Rights and Controlling Your Personal Information</h2>
            <h3>Choice and Consent</h3>
            <p>By using our website, purchasing a membership from us, participating in our events, or otherwise providing personal information to us, you consent to us collecting, holding, using, and disclosing your personal information in accordance with this Privacy Policy.</p>
            <p>If you are under 16 years of age, you must have your parent or legal guardian’s permission to access and use the website.</p>

            <h3>Definition of Minor for the Purpose of Consent to Collect Minor’s Data</h3>
            <p>Minors are defined as individuals under the age of 18 years old.</p>

            <h3>Consent by Guardian for Collection of Minor’s Data</h3>
            <p>As the parent or legal guardian of Minor, you consent to the collection and processing of personal data pertaining to Minor by PCSL:</p>
            <ul>
                <li><strong>Purpose of Data Collection:</strong> The data collected will be used solely for PCSL.</li>
                <li><strong>Types of Data Collected:</strong> Data may include, but is not limited to, the information defined above.</li>
                <li><strong>Storage and Processing:</strong> Data may be stored and processed securely by PCSL or its designated partners in accordance with applicable data protection laws.</li>
                <li><strong>Duration of Data Retention:</strong> Data will be retained only as long as necessary to fulfill the stated purpose or as required by law.</li>
                <li><strong>Right to Access and Rectify Data:</strong> You have the right to access and request correction of Minor’s personal data.</li>
                <li><strong>Disclosure to Third Parties:</strong> Minor’s personal data will not be disclosed to third parties without your explicit consent, except as required by law.</li>
                <li><strong>Withdraw of Consent:</strong> You have the right to withdraw this consent at any time by contacting us.</li>
                <li><strong>Contact Information:</strong> For inquiries regarding data privacy, please contact us at <a href="mailto:info@pcsleague.com">info@pcsleague.com</a>.</li>
            </ul>

            <h2 style={styles.subtitle}>Information from Third Parties</h2>
            <p>If we receive personal information about you or Minor from a third party, we will protect it as set out in this Privacy Policy.</p>

            <h2 style={styles.subtitle}>Restrict</h2>
            <p>You may choose to restrict the collection or use of your or Minor’s personal information.</p>

            <h2 style={styles.subtitle}>Access and Data Portability</h2>
            <p>You may request details of the personal information we hold about you or Minor. You may also request a copy or request the erasure of this personal information.</p>

            <h2 style={styles.subtitle}>Correction</h2>
            <p>If you believe that any information we hold about you or Minor is inaccurate, please contact us.</p>

            <h2 style={styles.subtitle}>Notification of Data Breaches</h2>
            <p>We will comply with laws applicable to us in respect of any data breach.</p>

            <h2 style={styles.subtitle}>Complaints</h2>
            <p>If you believe that we have breached a relevant data protection law, please contact us.</p>

            <h2 style={styles.subtitle}>Unsubscribe</h2>
            <p>To unsubscribe from our e-mail database or opt-out of communications, please contact us.</p>

            <h2 style={styles.subtitle}>Disclosure of Personal Information to Third Parties</h2>
            <p>User information may be shared with third-party partners as necessary to provide and improve our services. We may also provide your information to third parties under certain circumstances.</p>

            <h2 style={styles.subtitle}>International Transfers of Personal Information</h2>
            <p>The personal information we collect is stored and processed in the United States or where our partners maintain facilities. By providing us with your personal information, you consent to the disclosure to these overseas third parties.</p>

            <h2 style={styles.subtitle}>Email Policy</h2>
            <p>We will not share, sell, rent, swap, or authorize any third party to use your email address for commercial purposes without your permission.</p>

            <h2 style={styles.subtitle}>Third-Party Cookies</h2>
            <p>Cookies may be set by organizations other than PCSL. You should determine their policies by visiting the privacy policy pages of those sites directly.</p>

            <h2 style={styles.subtitle}>Changes to This Privacy Policy</h2>
            <p>We reserve the right to modify this privacy policy at any time. Changes will take effect immediately upon posting on the website. If we make material changes, we will notify you here.</p>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: '20px',
        padding: '20px'
    },
    title: {
        color: '#333',
    },
    subtitle: {
        color: '#333',
        marginTop: '20px',
    },
};

export default PrivacyPage;